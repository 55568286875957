var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-button-group" }, [
    _c("label", { staticClass: "radio-button-group-header" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "div",
      { staticClass: "radio-button-group-body" },
      [
        _vm._l(_vm.options, function (option) {
          return [
            _c("input", {
              key: `${_vm.label}radioButton${
                _vm.nativeId ? option[_vm.nativeId] : option.id || option
              }`,
              attrs: {
                type: "radio",
                id: _vm.nativeId ? option[_vm.nativeId] : option.id || option,
                name: _vm.label,
              },
              domProps: {
                value: _vm.nativeValue
                  ? option[_vm.nativeValue]
                  : option.value || option.id || option,
                checked: _vm.nativeValue
                  ? option[_vm.nativeValue] === _vm.value
                  : option.value === _vm.value ||
                    option.id === _vm.value ||
                    option === _vm.value,
              },
            }),
            _c(
              "label",
              {
                key: `${_vm.label}radioLabel${
                  _vm.nativeId ? option[_vm.nativeId] : option.id || option
                }`,
                attrs: {
                  for: _vm.nativeId
                    ? option[_vm.nativeId]
                    : option.id || option,
                },
                on: {
                  click: function ($event) {
                    return _vm.emitNewValue(
                      _vm.nativeValue
                        ? option[_vm.nativeValue]
                        : option.value || option.id || option
                    )
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.getLabel(option)) + "\n      ")]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }